import React, { useEffect, useState } from 'react';
import { getLocale, Divider, Tooltip, Spin, Empty, ConfigProvider, zh_CN, en_US, getLang } from '@didi/ec-base';
import classNames from 'classnames';
import { TimelineCard } from '@didi/ehr-timeline-card';
import { getPromotionHistory, getOperateLog } from './services/index';
import ArrowRightOutlined from './assets/ArrowRightOutlined.svg';
import RightOutlined from './assets/RightOutlined.svg';
import manifest from '../manifest.json';
import { Intl } from '@didi/pagelets-sdk';
import TalentModal from './components/TalentModal';
import './style.scoped.less';

const { render: { LineItem } } = TimelineCard;
const lang = getLang();

const Pagelet = (props) => {
  const { params: {
    locales,
    apiHost,
    headers,
    employeeId,
    usePermissionResponse,
    theme = 'blue',
    effectDate,// 日期
  } } = props;

  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [modalData, setModalData] = useState('');
  const [loading, setLoading] = useState(true);

  const getPromotionHistoryDetail = async () => {
    try {
      setLoading(true);
      const res = await getPromotionHistory(apiHost, headers, { employeeId, effectDate }, usePermissionResponse);
      setLoading(false);
      if (res?.code === '100200' && res?.data && Object.keys(res?.data)?.length > 0) {
        setData(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log('getPromotionHistoryDetail', error);
    }
  }

  // 接口请求
  useEffect(() => {
    if (employeeId) {
      getPromotionHistoryDetail();
    }
  }, [employeeId, effectDate]);

  // 多语言优先props，再取本地
  const langLocales = locales || manifest.manifest || {};
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames('pagelet-talent-promotion', {
    'pagelet-talent-promotion-orange': theme === 'orange', // 橙色主题
    'pagelet-talent-promotion-en-US': lang === 'en-US',
  });

  const HighlightText = ({ text, match }) => {
    if (!match) {
      return <span>{text}</span>;
    }
    const parts = text.split(new RegExp(match, 'ig'));
    return (
      <React.Fragment>
        <span>
          {parts.map((part, index) => (
            index % 2 === 0 ? (
              <>{part}<span>{match}</span></>
            ) : (
              part
            )
          ))}
        </span>
      </React.Fragment>
    );
  };

  const handleClick = (type, activeValue, targetDesc) => {
    setVisible(true);
    setTitle(targetDesc);
    setType(type);
    setModalData(activeValue);
    getOperateLog(apiHost, headers, {
      operateName: "点击",
      operateContent: type === 'nominate' ? '提名理由' : '晋升评价',
      operateParams: {
        "被浏览人工号": employeeId
      }
    }, usePermissionResponse);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <ConfigProvider prefixCls='ant5' locale={lang === 'en-US' ? en_US : zh_CN} theme={{
      token: { colorPrimary: theme === 'orange' ? '#FF6400' : '#0a70f5' }
    }}>
      <Spin spinning={loading}>
        <div className={classes}>
          {
            data && Object.keys(data).length > 0 && <div className='talent-record-promotion-box'>
              <div className='talent-record-promotion-info'>
                {data?.employmentYear && !effectDate && <HighlightText text={intl('{year} in DiDI', { year: data?.employmentYear })} match={data?.employmentYear} />}
                {effectDate && <span>{locale?.InDiDi}</span>}
                {
                  data?.promotionNum > 0 && <span>{locale?.Promoted}<span>{data?.promotionNum}</span>{data?.promotionNum > 1 ? locale?.times : locale?.time}</span>
                }
                {
                  data?.promotionSuccessNum > 0 && <span>{locale?.Succeeded}<span>{data?.promotionSuccessNum}</span>{data?.promotionSuccessNum > 1 ? locale?.times : locale?.time}</span>
                }
                {
                  data?.promotionFailNum > 0 && <span>{locale?.Failed}<span>{data?.promotionFailNum}</span>{data?.promotionFailNum > 1 ? locale?.times : locale?.time}</span>
                }
              </div>
              {data?.jobLevelMultipleNominations && <span className='job-multip-text'>{data?.jobLevelMultipleNominations}</span>}
            </div>
          }
          {
            data?.promotionDetails?.length > 0 && (
              <TimelineCard>
                {data?.promotionDetails?.map(item => (
                  <LineItem theme={theme} startTime={`${item.promotionYear}${item.yearType === '0' ? locale?.FirstHalf : locale?.SecondHalf}`}>
                    <div className={classNames('talent-promotion-container', { 'talent-promotion-failed': item?.promotionResult !== '1' })}>
                      <div className='promotion-result'>
                        {item?.isAppeal && <b>{item.isAppeal}</b>}
                        {item?.isUnconventionallyPromote && <b>{item.isUnconventionallyPromote}</b>}
                        {item?.isCrossSequence && <Tooltip title={`${item?.crossSequenceBeforeDesc} ~ ${item?.crossSequenceAfterDesc}`}><b style={{ cursor: 'pointer' }}>{item.isCrossSequence}</b></Tooltip>}
                        {item?.isSequenceChanged && <Tooltip title={`${item?.sequenceChangedBeforeDesc} ~ ${item?.sequenceChangedAfterDesc}`}><b style={{ cursor: 'pointer' }}>{item.isSequenceChanged}</b></Tooltip>}
                        {item?.promotionResult && <span className='promotion-result-tag'>{item?.promotionResultDesc}</span>}
                      </div>
                      <div className='text-box'>
                        <div className='promotion-name'>
                          <span>{locale?.JobLevel}</span>
                          <span>
                            {item?.preLevel}
                            <ArrowRightOutlined className='card-icon' />
                            <span className='promotion-level'>{item?.promotionLevel}</span>
                          </span>
                        </div>
                        {
                          item?.preJobLevelStayTime && <div className='promotion-name' style={{ marginTop: '6px' }}>
                            <span>{locale?.Duration}</span>
                            <span>{item?.preJobLevelStayTime}</span>
                          </div>
                        }
                      </div>
                      {(item?.promotionChairmanEvaluation ||
                        item?.appealChairmanEvaluation ||
                        item?.nominateReason) && <Divider type="horizontal" />}
                      <div className='promotion-item-btn'>
                        {item?.nominateReason && (
                          <span
                            onClick={() => {
                              handleClick('nominate', item, locale?.NominationReason);
                            }}
                          >
                            {locale?.NominationReason}
                            <RightOutlined />
                          </span>
                        )}
                        {(item?.promotionChairmanEvaluation || item?.appealChairmanEvaluation) && (
                          <span
                            onClick={() => {
                              handleClick('evaluation', item, locale?.ChairmanSummaryEvaluation);
                            }}
                          >
                            {locale?.PromotionEvaluation}
                            <RightOutlined />
                          </span>
                        )}
                      </div>
                    </div>
                  </LineItem>
                ))}
                <TalentModal
                  data={modalData}
                  type={type}
                  title={title}
                  visible={visible}
                  onCancel={handleCancel}
                  locales={locales}
                />
              </TimelineCard>
            )
          }
          {
            (!data || Object.keys(data).length === 0) && !loading && <Empty style={{ marginBottom: '12px' }} />
          }
        </div>
      </Spin>
    </ConfigProvider>
  );
};

export default Pagelet;
