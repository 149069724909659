import PageletApi from '@didi/pagelets-sdk';
import manifest from '../../manifest.json';
const apis = manifest.apis || {};

const { request } = PageletApi;

/**
 * 人才晋升
 * url: /api/archive/promotion-history
 * method: GET
 * @param  {object}  params
 * @return {Promise}
 */
export const getPromotionHistory = (apiHost, headers, params, usePermissionResponse) => request(`${apiHost}${apis.getPromotionHistory}`,
  {
    method: 'get',
    headers,
    params,
    usePermissionResponse
  }
);

export const getOperateLog = (apiHost, headers, data, usePermissionResponse) => request(`${apiHost}/api/archive/operateLog`,
  {
    method: 'post',
    headers,
    data,
    usePermissionResponse
  }
);
