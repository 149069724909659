import React from 'react';
import { getLocale, Modal, Tabs, ConfigProvider } from '@didi/ec-base';
import manifest from '../../../manifest.json';
import './style.scoped.less';

const TalentModal = props => {
  const { visible, onCancel, title, type, data, locales } = props;

  // 多语言优先props，再取本地
  const langLocales = locales || manifest.manifest || {};
  const locale = getLocale(langLocales);

  return (
    <ConfigProvider prefixCls="ant5">
      <Modal
        width={600}
        title={title}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        destroyOnClose
        className='pagelet-talent-promotion-modal'
      >
        <div className="talent-promotion-modal-content">
          {type === 'evaluation' && data?.appealChairmanEvaluation ? (
            <Tabs
              size="middle"
              defaultActiveKey={'1'}
              className="talent-promotion-modal-tabs"
              barType="auto"
              destroyInactiveTabPane
              items={[
                {
                  key: '1',
                  label: locale?.PromotionEvaluation,
                  children: (
                    <div className="talent-promotion-modal-evaluation-info">
                      <div className="talent-promotion-modal-evaluation-info-text">{data?.promotionChairmanEvaluation}</div>
                    </div>
                  ),
                },
                {
                  key: '2',
                  label: locale?.EscalateEvaluation,
                  children: (
                    <div className="talent-promotion-modal-evaluation-info">
                      <div className="talent-promotion-modal-evaluation-info-text">{data?.appealChairmanEvaluation}</div>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div className="talent-promotion-modal-evaluation-info">
              <div className="talent-promotion-modal-evaluation-info-text">
                {type === 'evaluation' ? data?.promotionChairmanEvaluation : data?.nominateReason}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default TalentModal;
